/*
 * Flex UX Framework
 *
 * Filename: footer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 .footer {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: space-between;

	background: $footer-background;
	color: #fff;
	font-family: $primary-font-face;
	font-size: 14px;
	height: 65px;
	min-height: 25px;
	padding: 0 25px;
	padding-right: 11px;

	img {
		height: 44px;
		margin-right: 20px;
		display: none;

		@include mq("tablet", "max") {
			height: 25px;
			margin-bottom: 20px;
			margin-right: 0px;
		}
	}

	a {
		color: #fff;
		text-decoration: none;
		// text-transform: capitalize;
		font-weight: initial;
		font-size: 14px;
		font-family: $secondary-font-face;

		&:hover {
			color: #fff;
		}
	}

	#call-link {
		display: none;
	}

	>div {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-family: $secondary-font-face;
		font-weight: initial;
		line-height: 1.35em;

		@include mq("tablet", "max") {
			flex-direction: column;
			justify-content: space-around;
		}

		&:nth-of-type(2) {
			@include mq("phone-wide", "max") {
				margin-top: 10px;
			}

			@media only screen and (max-width: 330px) {
				// margin-top: 25px;
				margin-bottom: 10px;
			}
		}

	}
}

@include mq("tablet", "max") {
	.footer {
		min-height: 170px;
		flex-flow: column nowrap;
		text-align: center;
		padding: 30px;
	
		#call-link {
			display: inline-flex;
	
			&>a {
				font-size: 20px;
				font-family: $primary-font-face !important;
			}
		}
	
		#call-link a {
			display: inline-flex;
			text-decoration: none;
		}
	
		#call-link a::before {
			content: '\e0cd';
			height: 25px;
			width: 25px;
			font-family: "Material Icons";
			font-size: 22px;
			// color: #fff;
			padding-right: 5px;
		}
	
		.footer-nav-menu {
			padding-top: 0;
		}
	}
}

@include mq("tablet-small", "max") {
	.footer {
		padding: 20px;
		padding-bottom: 10px;
		height: auto;

		#call-link {
			margin-bottom: 10px;
		}

		div:nth-child(2) {
			margin-bottom: 15px;
		}

		.footer-nav-menu {
			justify-content: center;
			flex-wrap: wrap;
		}
	}
}

@include mq("phone-wide", "max") {
	.footer {
		#call-link {
			margin-top: 10px;
		}

		.footer-nav-menu {
			line-height: 40px;
		}
	}
}

@include mq("phone", "max")  {
	.footer .footer-nav-menu {
		flex-wrap: wrap;
	}
}