/*
 * Flex UX Framework
 *
 * Filename: wishlist-content.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.wishlist {
	main {
		background: #fff !important;
		min-height: calc(100vh - 65px) !important;
	}

	form {
		.f-field {

			textarea,
			input {
				@include mq("phone-wide", "max") {
					width: 100%;
				}
			}
		}

		.f-row,
		.f-buttons {
			margin-bottom: 10px;
		}

		.f-row {
			label {
				display: block;
				margin-bottom: 5px;
			}
		}

		.f-buttons {
			display: flex;

			.btn--secondary {
				display: flex;
				align-items: center;

				color: #fff;
				font-size: 16px;
			}

			input {
				padding: 2px 5px;
				width: 100px;
				// border: none;

				@include mq("phone-wide", "max") {
					width: 50%;
				}
			}
		}
	}

	.page-body {
		max-width: 600px;
		margin: 0px auto;
		padding: 12px 12px 50px 12px;

		p:first-of-type {
			line-height: 18px;
			margin-bottom: 5px !important;
		}

		a {
			font-size: 14px;
			// color:$brand-color;
		}

		.btn--secondary {
			display: flex;
			align-items: center;
			font-size: 16px;

			&:hover {
				color: #fff;
			}
		}
	}

	.btn-continue-shopping {
		margin-top: 20px;
	}

	.wishlist-grid {
		padding-top: 24px;

		@include mq("phone-wide", "max") {
			padding: 15px;
		}

		textarea {
			width: 100%;
			min-height: 64px;
			min-width: 200px;
			margin-bottom: 10px;
			padding: 10px;
		}


		.wishlist-product-cell {
			margin-bottom: 50px;

			@include mq('tablet', 'max') {
				margin-bottom: 10px;
			}

			.product-card {
				@include mq("phone-wide", "max") {
					width: calc(100% - 28px);
				}
			}

			.mdl-checkbox {
				float: left;
				width: 0px;
			}
		}

		.wishlist-controls-cell {
			input {
				margin-top: 10px;
				text-align: center;
				height: 45px;
				font-family: $secondary-font-face;
			}
		}
	}

	.mdl-cell {
		font-size: 14px;
		margin-left: 0px;

		&.wishlist-controls-cell {
			margin-right: 0;
			width: 100%;
		}

		@include mq('tablet', 'max') {
			margin-bottom: 50px;
		}
	}
}

@include mq("phone-wide", "max") {
	.wishlist {
		.wishlist-grid {
			.wishlist-product-cell {
				margin-left: auto;
				margin-right: auto;
				max-width: 300px;

				.product-card {
					max-width: 250px;
				}
			}
		}
	}
}

@include mq("phone", "max")  {
	.wishlist {
		.mdl-cell {
			margin-right: 0;
			width: 100%;

			&.wishlist-controls-cell {
				margin-bottom: 0;

				input {
					width: 100%;
				}
			}
		}
	}
}