/*
 * Flex UX Framework
 *
 * Filename: spotlight_slider.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.page-header .hero-carousel-overlay .hero-text {
	font-family: $primary-font-face;
	position: absolute;
	pointer-events: none;
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	width: 258px;
	letter-spacing: 4px;
	margin-left: 159px;
	color: white;
	font-size: 115px;
	font-weight: 600;
	line-height: 0.8;
	text-transform: uppercase;
}

.hero-carousel {
	width: 100%;
	max-width: 100%;
	margin-bottom: 0;

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		opacity: 1;
		width: 1354px;
		position: relative;
		top: 0;
		left: 0;
		display: block;

		.hero-slide {
			/*background-image: url("store/20190204969/assets/images/spotlights/HOME_desktop_450_1024.jpg");*/
/*			width: 1354px;
			height: 450px;*/
			background: linear-gradient(90deg, #00843D 0%, #000000 100%);
			position: relative;
			left: 0px;
			top: 0px;
			z-index: 999;
			opacity: 1;
			background-position: center;
			background-size: cover !important;
			overflow: hidden;
			display: flex;
			width:  100vw! important;

				@include mq("phone-smallest", "min") {
							background-image: url("../../../images/spotlights/HOME_mobile_300_400.jpg") !important;
/*							width: 600px !important;
							height: 250px !important;*/
						}

						@include mq("phone", "min") {
							background-image: url("../../../images/spotlights/HOME_mobile_300_400.jpg") !important;
/*							width: 750px !important;*/
							height: 300px !important;
						}

						@include mq("desktop", "min") {
							background-image: url("../../../images/spotlights/HOME_tablet_375_768.jpg") !important;
/*							width: 1000px !important;
							height: 375px !important;*/
						}			
	

			@include mq("desktop", "min") {
				background-image: url("../../../images/spotlights/HOME_desktop_450_1024.jpg") !important;
/*				width: 1500px !important;
				height: 450px !important;*/
			}
		}
	}

	.slick-track::before,
	.slick-track::after {
		display: table;
		content: '';
	}
}


@include mq("tablet-small", "max") {
	.hero-carousel .slick-track .hero-slide {
		height: 320px;
	}
}

@include mq("phone", "max")  {
	.hero-carousel .slick-track .hero-slide {
		height: 235px;
	}
}
