/*
 * Flex UX Framework
 *
 * Filename: item-options.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product {
	&-options-container {
		position: relative;
		box-sizing: border-box;
		border: 1px solid rgba(226, 226, 226, 0.5);
		padding: 20px;
		margin-top: 30px;

		@include mq("tablet", "max") {
			padding: 10px;
		}
	}

	&-quantity-details {
		display: flex;
		align-items: center;

		.product-quantity {
			min-width: 60px;
			width: 75px;
			margin-right: 25px;

			form {
				display: flex;
				align-items: center;

				&:before {
					content: "QTY: ";
					font-family: $secondary-font-face;
					margin-right: 5px;
					font-weight: 700;
					font-size: 14px;
				}
			}
		}
	}

	&-image-option {
		&-title {
			text-transform: uppercase;
			font-family: $secondary-font-face;
			font-size: 14px;
			margin-bottom: 10px;
			font-weight: 700;
			color: #000;
		}

		&-name {
			// color: $primary-font-color;
			color: $brand-color;
		}

		&-thumbnails {
			display: flex;
			margin-left: -5px;
			flex-wrap: wrap;

			@include mq("tablet", "max") {
				margin-bottom: 10px;
				margin-left: 0px;
			}
		}

		&-thumbnail {
			height: 70px;
			width: 70px;
			margin: 5px;
			text-align: center;
			cursor: pointer;
			outline: 2px solid #f7f7f7;

			&.active,
			&:hover {
				outline: 2px solid $brand-color;
			}
		}
	}
}

.product-quantity-details {
	.product-quantity {
		form {
			margin-bottom: 0;

			input {
				font-size: 13px;
				font-family: $secondary-font-face;
				color: #000;
				font-weight: 700;
			}
		}

		.mdl-textfield.is-dirty {
			color: #333;
		}

		.mdl-textfield {
			color: #BFBFBF;
		}

		.mdl-textfield.is-dirty .mdl-textfield__label {
			color: $brand-color;
		}
	}

	.quantity-available {
		font-weight: 700;
		line-height: 1.25em;
	}

	.remove-btn {
		margin-left: auto;
		font-size: 11px;
		letter-spacing: 2px;
	}

}

.select-option-item {
	&:hover {
		color: $brand-color;
		background-color: #f7f7f7;
	}
}