/*
 * Flex UX Framework
 *
 * Filename: faqs.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {
	main {
		background: #fff;
	}

	.page-header {
		height: 0;
	}

	.page-body {
		padding: 12px 12px 50px 12px;
		margin: 0 auto;
		max-width: 625px;
	}
}