/*
 * Flex UX Framework
 *
 * Filename: my_account.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */




.my-account {
	main {
		background: #fff !important;
	}

	.my-account-billing {
		.first-col--country {
			display: flex;
			justify-content: flex-end;

			@include mq("tablet", "max") {
				display: block;
			}
		}

		.select2 {
			padding-top: 0 !important;
			margin-top: 20px;
		}

		.select2-dropdown-container {
			max-width: 300px;

			@include mq("tablet", "max") {
				display: inline-block;
				margin-right: auto;
				width: 100%;
			}

			.country-options-container,
			.province-options-container {
				width: 100%;
				height: 67px;

				.select2-container {
					flex: 1 1 100%;
					width: 100% !important;
					padding-top: 20px;

					.select2-selection {
						border-left: 0 !important;
						border-right: 0 !important;
						border-top: 0 !important;
						border-radius: 0px !important;
					}
				}

				.select2-selection__rendered {
					text-align: left !important;
				}
			}
		}


		@include mq("tablet", "min") {
			.select2-dropdown-container {
				min-width: 300px;
			}
		}
	}

	.page-body {
		max-width: 1175px;
		margin: auto;
		padding: 12px;
		margin-top: 9px;

		@include mq("phone-wide", "max") {
			padding: 6px;
			margin-top: 0;
		}

		.mdl-grid {

			@include mq('phone-wide', 'min') {

				.first-col {
					text-align: right;
				}
			}

			.button-col {

				// @include mq("tablet-small", "min") {
				// 	.mdl-button {
				// 		margin-left: 528px;
				// 	}
				// }
			}

			.address-note {
				position: absolute;
				font-size: 12px;
				margin-top: 3px;
				display: block;
				color: #AAA;
			}

			.mdl-cell--6-col {
				@include mq("phone-wide", "max") {
					text-align: center;
				}
			}

			.mdl-cell--12-col {
				text-align: center;

				.mdl-textfield {
					width: 616px;
				}

				@include mq("phone-wide", "max") {
					margin: 8px auto;
					width: 100%;
					max-width: 300px;
				}
			}
		}
	}
}

.select2-dropdown-label {
	padding-bottom: 0;
	padding-top: 0;
	height: 0;
	width: 100%;
	text-align: center;
	cursor: default;
	color: $brand-color;
	font-size: 12px;
}

@include mq("phone", "max") {
	.my-account .page-body .btn--primary {
		width: 100%;
	}
}