/*
 * Flex UX Framework
 *
 * Filename: product_list.scss
 * Type:     Component Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

//@TODO: factor below media queries, convert to mq mixin, push margin to approperate parent class
.new-products-grid {
	justify-content: space-between;
}

.page-body {

	.products-container {

		.grid {
			flex: 1;
			display: flex;
			flex-flow: row wrap;
			overflow: hidden;

			//margin: 0 -12px;
			@include mq('tablet', 'max') {
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.mdl-tabs .products-tab-panels .grid {
	justify-content: center;
}

.product-list {
	// was: .grid
	display: flex;
	flex-flow: row wrap;
	overflow: hidden;
	width: 100%;
}

@include mq("tablet-small", "max") {
	.product-list {
		width: calc(100% - 72px);
	}
}

@include mq("phone-wide", "max")  {
	.product-list {
		width: calc(100% - 20px);
	}
}