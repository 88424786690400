/*
 * Flex UX Framework
 *
 * Filename: checkout_multibilltoaddr.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container {
	display: flex;
	flex-wrap: wrap;

	@include mq("tablet", "min") {
		margin: 20px;
	}

	i {

		&.material-icons {
			color: $brand-color;
		}
	}

	.checkout-main {
		display: flex;
		flex-flow: column;
		width: 100%;
		border-radius: 5px;

		@include mq("tablet", "min") {
			flex: 75 1;
			margin-right: 20px;
			height: 100%;
		}

		&-top {
			margin-bottom: 20px;
			background: #fff;
			border-radius: 5px;
		}

		&-bottom {
			flex: 1 1 100%;
			background: #fff;
			border-radius: 5px;
		}

		p {
			font-size: 13px;
			line-height: 20px;
		}
	}

	.checkout-side {
		flex: 25 1;
		border-radius: 5px;

		@include mq("tablet", "max") {
			margin-top: 10px;
		}

		&-top {
			flex-flow: column;
			padding: 0;

			&--addresses {
				padding: 16px !important;
				min-height: 125px;

				h2 {
					margin-bottom: 0;
					padding-left: 0;
					padding-right: 0;
					padding-top: 0;
					padding-bottom: 8px !important;
				}
			}
		}

		&-bottom {
			display: flex;
			flex: 1 1 100%;
			flex-flow: column;
			padding: 0;
			margin-top: 20px;
		}
	}
}