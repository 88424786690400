/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-continue.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container {
	.btn-main{
		@include mq("tablet", "min") {
			&--continue-checkout {
    			width: 100%;
    		}
    	}
    }
}