/*
 * Flex UX Framework
 *
 * Filename: item-add-buttons.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.add-another-btn-container {
	padding: 10px 0;

	.add-another-btn {
		color: $secondary-font-color;
		font-family: $secondary-font-face;
		font-size: 14px;
		font-weight: 700;

		@include mq("phone-wide", "max") {
			&.mdl-button {
				text-align: left;
				margin-bottom: 5px;
			}
		}

		.material-icons.add-icon {
			margin-top: -2px;

			color: $brand-color;
			font-size: 24px;
		}

		&:hover {
			background-color: #f0f0f0;
		}
	}
}

.add-to-list-buttons {
	display: flex;
	padding: 30px 0 20px;

	border: 1px solid rgba(226, 226, 226, 0.5);
	border-left: none;
	border-right: none;

	@include mq("phone", "max") {
		flex-direction: column;
	}
}

.add-to-wishlist-btn,
.add-to-cart-btn {
	margin-bottom: 10px;
	height: auto;
	min-height: 45px;

	text-transform: uppercase;

	@include mq("phone-wide", "max") {
		margin: 0;
		margin-bottom: 10px;
		width: 100%;
	}
}

#add-to-cart-form {
	margin: 0 !important;
}

