/*
 * Flex UX Framework
 *
 * Filename: custom-orders-content.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.custom-orders {
	.page-body {
		.custom-order-form {
			display: flex;
			flex-flow: column;
			margin: 10px auto 50px;

			input,
			textarea,
			select {
				padding: 10px;
				margin-bottom: 15px;

				font-family: $primary-font-face;
				font-size: 14px;
				
				border: 1px solid #e0e0e0;
			}

			.custom-select {
				position: relative;

				&:after {
					content: "\276F";
					position: absolute;
					top: 10px;
					right: 10px;
					transform: rotate(90deg);
				}
			}

			select {
				padding: 10px;
				color: #000;
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				width: 100%;
				height: 41px;
				background-color: #fff;
				line-height: 1.2;
			}

			button {
				align-self: center;
				padding: 10px 25px;
				min-width: 185px;

				border: none;

				&:hover {
					cursor: pointer;
				}
			}
		}

		p {
			margin-bottom: 15px;
			
			font-size: 14px;
			line-height: 21px;
		}
	}

	input {
		color: #000;
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #000;
		opacity: 1;
		/* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #000;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #000;
	}
}

@include mq("phone", "max") {
	.custom-orders {
		.page-body {
			.custom-order-form {
				margin-bottom: 20px;

				button {
					width: 100%;
					min-width: inherit;
				}
			}
		}
	}
}