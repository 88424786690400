/*
 * Flex UX Framework
 *
 * Filename: checkout-review-shipping.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout-review .checkout-container {

    .checkout-main {
        //background: #fff;
    }
    
    .shipping-row {
        display: block !important;
        background: #fff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        .shipping-address-info {
            width: 100%;
        }

        .shipping-row__content {
            padding: 0px 10px;
            width: calc(100% - 42px);
            min-height: 62px;
            font-family: $primary-font-face;
            font-size: 14px;
            line-height: 1.25;

            @include mq('tablet','max'){
                padding-top: 0px 15px;
            }

            span {
                display: inline-block;
                width: 100%;
            }

            .address-line--title{
                font-weight: 700;
            }
        }
    }

    .shipping-row.shipping-row--method {
        border-bottom: 1px solid #efeff1;
        .shipping-method-info{
            p{
                padding: 0px 10px;
                line-height: 1.25;

                @include mq('tablet','max'){
                    padding-top: 0px 15px;
                }
            }
        }
    }
}

.checkout-container{

    .shipping-address-info {
        display: flex;
    }

    .shipping-method-info {
        display: flex;
        flex: 1 1 100%;

        p {
            margin-bottom: 0;
            align-self: center;
            font-weight: bold;
            line-height: 16px;
        }

        span{
            display: block;
            font-weight: normal;
            line-height: 16px;
        }
    }
}

.checkout-review .checkout-container .items {
    border-bottom: 1px solid #efeff1;
    // flex-basis: 100%;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .mdl-list {
        padding-top: 0;
        margin-top: 0;
    }

    .mdl-list__item {
        font-family: $primary-font-face;
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        line-height: 1.25;
        letter-spacing: 0;
        color: #000;   
        span {
            flex: 1 1 100%;
            .price {
                text-align: right;
                float: right;
                font-weight: normal;
            }
        }
        .title{
            font-weight: 700;
        }
    }
}
