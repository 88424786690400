/*
 * Flex UX Framework
 *
 * Filename: basket.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout {
	h1 {
		margin-top: 0;

		@include mq("phone", "min") {
			margin-top: 15px;
		}
	}

	.page-body {
		max-width: 1175px;
		margin: 0px auto;
		padding: 12px 12px 50px 12px;
		// height: calc(100vh - 226px);

		@include mq("tablet", "max") {
			min-height: 100vh;
			height: auto;
		}
	}

	.page-header {
		height: 0;
	}
}

.checkout-basket {

	.checkout-container {
		display: flex;
		flex-wrap: wrap;

		@include mq('tablet', 'max') {
			margin: 0px;
		}

		@include mq('tablet', 'min') {
			margin: 20px;
		}

		.checkout-main {
			display: flex;
			flex-flow: column;

			@include mq('tablet', 'max') {
				flex: 1 1 100%;
			}

			@include mq('tablet', 'min') {
				flex: 75 1;
				margin-right: 20px;
			}

			.checkout-main-top {
				margin-bottom: 20px;

				background-color: #fff;
				border-radius: 5px;

				.checkout-item {
					display: flex;
					white-space: nowrap;
					border-bottom: 1px solid #efeff1;

					.checkout-item-main {
						display: flex;
						align-items: center;
						flex: 8 8 80%;
						font-size: 13px;
						line-height: 1.15em;

						@include mq('phone-wide', 'min') {
							padding: 25px 20px 25px 25px;
						}

						.checkout-item-caption {
							display: flex;
							align-self: flex-start;
							flex-direction: column;

							p {
								color: #e60000 !important;
								white-space: normal;
								margin: 8px 0px 2px 0px;
								line-height: 1.25em;
							}

							@include mq('tablet', 'max') {
								max-width: 60%;
							}

							&--title {
								font-weight: 700;
								margin-bottom: 3px;
								font-size: 13px;

								@include mq('tablet', 'max') {
									word-wrap: normal;
									white-space: pre-line;


								}
							}

							&--code {
								@include mq('desktop', 'min') {
									word-wrap: normal;

									@include mq("phone-mid", "max") {
										white-space: normal;
									}
								}

								@include mq("phone-wide", "max") {
									white-space: pre-line;
								}
							}

							p.delivery-overorder-message {
								padding-top: 6px;
								line-height: 1.15;
							}

							@include mq("phone-wide", "max") {
								p.delivery-overorder-message {
									white-space: normal;
								}
							}
						}

						.delivery-options {
							display: flex;
							flex-flow: row;
							flex-wrap: wrap;
							// white-space: normal;
							// padding-inline-start: 0;
							padding-left: 0;
							margin-top: 6px;

							@include mq("phone-wide", "max") {
								white-space: normal;
							}

							li {
								flex: 1 1 100%;
								word-wrap: break-word;
								height: auto;
								line-height: 18px;

								label {

									&.mdl-radio__label {
										font-size: 13px;
										line-height: 1.25em;
									}
								}
							}
						}

						a {
							text-align: center;
							// margin-right: 25px;
							// display: flex;
							height: 100%;
							margin-right: 5px;
						}

						img {
							@include mq('tablet', 'max') {
								max-width: 75px;
								max-height: 75px;
								padding-left: 5px;

								@include mq("phone-wide", "max") {
									padding-top: 10px;
								}
							}

							@include mq('tablet', 'min') {
								max-width: 100px;
								max-height: 100px;
							}
						}
					}



					.checkout-item-controls {
						flex-grow: 2;
						text-align: right;
						box-sizing: border-box;

						@include mq('tablet', 'max') {
							padding: 10px;
						}

					}

					&--control {
						padding-top: 3px;
						padding-bottom: 3px;
						width: 100%;
						margin-bottom: 10px;

						.mdl-textfield {
							padding: 0;
						}

						&__remove {
							margin-top: 10px;

							span {
								text-decoration: none;
								text-transform: uppercase;
								font-weight: bold;
							}
						}
					}

					&--quantity {
						max-width: 35px;
					}

					&--update-quantity {
						cursor: pointer;
						text-decoration: underline;
						color: $link-color;

						&:hover {
							color: $link-hover;
						}
					}

					&--remove {
						cursor: pointer;
					}

				}

				// checkout-item
			}
		}

		// .checkout-main
		.checkout-side {

			@include mq('tablet', 'max') {
				flex: 1 1 100%;
			}

			@include mq('tablet', 'min') {
				flex: 25 1;
			}

			.checkout-side-top {
				flex-flow: column;
				justify-content: center;
				padding: 0;

				background: #fff;
				border-radius: 5px;

				@include mq('tablet', 'max') {
					min-height: 141px;
				}

				.checkout-basket-subtotals {
					flex-flow: row;
					border-bottom: 1px solid #efeff1;
					display: flex;
					flex: 1 1 100%;
					font-size: 18px;

					@include mq('tablet', 'max') {
						padding: 10px;
					}

					@include mq('tablet', 'min') {
						padding: 20px;
					}

					.checkout-subtotals {
						&--subtotal-label {
							flex: 0 4 40%;
						}

						&--subtotal {
							font-weight: bold;
							flex: 0 6 60%;
							text-align: right;
						}
					}
				}
			}
		}

	}

	// .checkout-container
	@include mq("tablet-small", "max") {
		.checkout-main-saved--items {
			width: 100%;

			.grid-cell.product-card {
				flex: 0 0 46%;
				width: 46%;
			}
		}
	}

	@include mq("phone", "max") {
		.checkout-main-saved--items {
			width: 100%;
			justify-content: space-between;
			margin: 15px 0 0;
	
			.grid-cell.product-card {
				flex: 0 0 48%;
				width: 48%;
				margin: 0 !important;
			}
		}
	}
}

// .checkout-basket

.checkout-basket-buttons {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	max-width: 250px;
	text-align: center;
	flex: 1 1 100%;
	font-size: 18px;

	@include mq('tablet', 'max') {
		padding: 10px;
	}

	@include mq('tablet', 'min') {
		padding: 20px;
	}

	.btn-main {
		background: $brand-color;
		color: white;
		font-family: $secondary-font-face;
		font-size: 10px;
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 0px;
		padding: 0 24px;

		@include mq('tablet', 'max') {
			min-height: 36px;
		}
	}

	.btn--primary {
		font-size: 16px;
		font-family: $secondary-font-face;
		padding: 0px 15px;
		transition: background 0.15s;
		height: 45px;
		letter-spacing: 0px;
		font-weight: 700;
		border: none;
		background-color: $brand-color-btn-primary;
		border-radius: 5px;

		// color: #3B3B3B;
		&:hover {
			background-color: $brand-color-btn-primary-hover !important;
		}

		@include mq("phone-wide", "max") {
			// min-width: 202.5px;
			width: 100%;
		}
	}

	.btn--secondary {
		@include mq("phone-wide", "max") {
			width: 100%;
		}
	}

	.btn--light {
		font-size: 16px;
		font-family: $primary-font-face;
		padding: 0px 15px;
		transition: background 0.15s;
		border: 1px solid #F2F2F2;
		background-color: #FFF;
		height: 45px;
	}

	.checkout-basket-taxwarning {
		display: flex;
		align-self: center;
		text-align: center;
		margin: 15px;
		width: 150px;
	}

	.checkout-small-text {
		font-size: 13px;
		line-height: 1.25em;
		padding-bottom: 3px;
		padding-top: 3px;
	}
}