/*
 * Flex UX Framework
 *
 * Filename: general.scss
 * Type:     General Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

//Universal Element Styles
* {
	box-sizing: border-box;
}

html {
	height: 100%;
	color: #000;
	border: 0;
	margin: 0;
	padding: 0;
}

body {
	width: 100%;
	height: auto !important;
	min-height: 100%;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	font-family: $primary-font-face;
	line-height: 1;
	font-size: 13px;
}

input,
textarea,
select {
	font-family: $primary-font-face;
	// color: $primary-font-color;
	line-height: 1;
	font-size: 13px;
	border-radius: 5px;
}

#brandSelect {
	line-height: 1.2;
}

// Hide X from search bar for IE/Chrome.
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
	display: none;
}

body,
input {
	@include mq("tablet", "max") {
		font-size: 13px;
	}
}

.select2-container--default .select2-selection--single {
	border-color: #e0e0e0;
}


a {
	color: $link-color;
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		color: $link-hover;
	}
}

p {
	line-height: 1.5em;
	margin: 0px;
	color: #000;
	font-family: $primary-font-face;
	font-size: 14px;
}

img {
	max-width: 100%;
	max-height: 100%;
}

h1 {
	line-height: 1.15;
	font-family: $primary-font-face;
	color: $h1-font-color;
	letter-spacing: normal !important;
	;
	font-weight: 700;
	font-size: $h1-font-size;
	margin: 15px 0px;
}

h2 {
	margin: 0;
	line-height: 1.2;
	font-family: $primary-font-face;
	color: $h2-font-color;
	letter-spacing: 0;
	font-weight: 700;
	//     text-transform: uppercase;
	font-size: $h2-font-size;

	@include mq("tablet", "max") {
		font-size: 20px;
	}
}

main {
	background-color: #ebebeb;
}

@include mq('phone-wide', 'max') {
	.desktop-only {
		display: none !important;
	}
}

@include mq('phone-wide', 'min') {
	.mobile-only {
		display: none !important;
	}
}

//Universal Page Styles
.page-body {
	max-width: $body-max-width;
	padding: 7px 12px 12px;
	margin: 9px auto auto;
	//margin-bottom: 50px;

	// @include mq("tablet", "max") {
	//     margin-bottom: 50px;
	// }

	// &>h1:first-of-type {
	//     font-weight: 900;
	//     padding-top: 10px;
	//     padding-bottom: 20px;
	//     text-transform: capitalize;
	//     font-size: 30px;
	//     line-height: 34px;
	//     margin-bottom: 20px;
	//     color: $primary-font-color;
	//     margin: 10px 0 0;
	// }
}

.product-card,
.product-cell {
	margin: 0 0 24px;

	@include mq("phone", "max")  {
		margin-bottom: 10px;
	}
}

.grid {
	@include mq("tablet", "min") {
		//margin: 0 !important;
	}
}

/***** Button Styles *****/
button {
	font-size: 'Open Sans';
}

.btn,
.btn--light,
.btn--white,
.btn-main,
.btn--dark,
.btn--primary,
.btn--secondary,
.checkout-btn,
.gift-certificate-button {
	padding: 2px 18px;
	width: auto;
	height: 45px;

	font-family: $primary-font-face;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;

	border-radius: 5px;

	transition: background 0.15s;
}

.btn--primary,
.btn--secondary,
.checkout-btn {
	color: #fff;
	// word-spacing: 1px;
}

.btn--primary,
.checkout-btn {
	background-color: $brand-color-btn-primary;
	border: 0;

	&:hover {
		background-color: $brand-color-btn-primary-hover;
	}
}

.btn--secondary {
	background-color: $brand-color-btn-secondary;

	&:hover {
		background-color: $brand-color-btn-secondary-hover;
	}
}

.btn--dark {
	color: #fff;

	background: black;
	border: 1px solid #fff;
}

.btn--white {
	color: $primary-font-color;

	background: #fff;
	border: none;

	&:hover {
		background-color: $brand-color-btn-light-hover;
	}
}

.btn--light {
	color: #000;

	background-color: $brand-color-btn-light;
	border: 1px solid #e5e5e5;

	&:hover {
		background-color: $brand-color-btn-light-hover;
	}
}

.gift-certificate-button {
	border: 1px solid #E5E5E5;
}

s .wishlist,
.login,
.forgot-password {
	.btn--light {
		// -webkit-box-shadow:inset 0px 0px 0px 2px #D52B1E;
		// -moz-box-shadow:inset 0px 0px 0px 2px #D52B1E;
		// box-shadow:inset 0px 0px 0px 2px #D52B1E;
		background: white;
		color: $brand-color;
		cursor: pointer;

		&:hover {
			background: #F7F7F7;
			color: $brand-color-btn-primary-hover;
		}
	}
}

/***** End of Button Styles *****/

// Material Design Overrides
.mdl-layout {
	a.osano-cm-link {
		color: $link-color;

		&:hover {
			color: $link-hover;
		}
	}

	.osano-cm-accept {
		background-color: $brand-color;

		&:hover {
			background-color: $brand-color-btn-primary-hover;
		}
	}
}

.mdl-layout--fixed-drawer>.mdl-layout__drawer-button {
	@include mq("tablet", "min") {
		display: none;
	}
}

.mdl-layout__container {
	pointer-events: none;
}

.gift-certificate-textfield {
	.mdl-textfield__label {
		font-size: 12px;
		text-align: center;
	}
}

.mdl-textfield {
	.mdl-textfield__label {
		color: #666666 !important;

		&:after {
			background-color: $brand-color !important;
			height: 0px;
		}
	}

	&--floating-label.is-dirty,
	&--floating-label.is-focused {
		.mdl-textfield__label {
			color: $brand-color !important;
		}
	}

	&__input {
		padding-left: 3px;
		font-family: $primary-font-face;
		font-size: 13px !important;
		color: #000 !important;
	}
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
	border: 2px solid $brand-color;
}

.duetDateInput {
	color: transparent !important;
}

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
	background-color: #000;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
	background-color: $brand-color;
}

.checkout {
	.page-body {
		h1:first-of-type {
			//margin-left: 20px;

			@include mq("phone-mid", "max") {
				padding-top: 15px;
				margin-left: 0px;
			}
		}
	}

	&-main-top {
		background: #fff;
	}

	&-side-top {
		background: #fff !important;
		border-radius: 5px;
	}
}


/* 
 * Modal Dialogs
 */

.mdl-dialog {
	width: calc(100% - 80px);
	max-width: 760px;

	p {
		color: $primary-font-color;
	}

	a {
		color: $brand-color;
	}

	.close-container {
		position: absolute;
		top: 0;
		right: 0;
		margin: 20px;
	}

	.modal-title {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid #efeff1;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 10px;
		padding-bottom: 10px;
		min-height: 55px;
		font-size: 23px;
		text-transform: uppercase;
		line-height: 1.75;
		height: auto;

		.modal-title {

			&__major {
				flex: 1 1 100%;
				align-self: flex-end;
				line-height: 1.15;
				text-align: center;
				font-size: 23px;
				text-transform: capitalize;
				color: #000;
				font-weight: 700;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&__minor {
				flex: 1 1 100%;
				align-self: flex-start;
				line-height: 1.25;
				font-size: 14px;
				font-weight: normal;
				color: #000;
				text-transform: capitalize;
				text-align: center;
				margin-top: 10px;
			}
		}

		.mdl-dialog__content {
			padding: 0 0 24px 24px;

			.material-icons {
				font-size: 18px;
			}

			p {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	.mdl-dialog__actions {
		display: flex;
		flex-direction: column;
		align-content: center;
		padding: 0;
		align-items: center;

		div {
			display: flex;
			align-content: center;
			justify-content: center;

			.mdl-checkbox {
				display: flex;
				justify-content: center;
				// margin: 0 auto;
				max-width: 215px;
				width: 100%;
			}

			.mdl-checkbox__label {
				font-size: 14px;
				font-weight: normal;
				color: #000;
				text-transform: capitalize;
				line-height: 1.25;
				display: flex;
				align-items: center;
			}
		}

		.mdl-button {
			min-height: 45px;
			height: auto;
		}
	}
}

.mdl-card {
	border-radius: 5px;
}

.select2-dropdown-container {

	.select2 {
		outline-style: none;
	}

	.country-options-container .select2-container,
	.province-options-container .select2-container {
		flex: 1 1 100%;
		width: 100% !important;
		padding-top: 20px;

		.select2-selection {
			border-left: 0;
			border-right: 0;
			border-top: 0;
			border-radius: 0px;
		}
	}

	.select2-container {
		box-sizing: border-box;
		display: inline-block;
		margin: 0;
		position: relative;
		vertical-align: middle;
	}
}

/******* Dialog-address-modal ********/

.dialog-address-modal {
	border-radius: 5px;

	.select2-selection__arrow {
		top: 21px !important;
	}

	.mdl-textfield {
		width: 100%;
	}

	.add-button-container {
		display: flex;
		justify-content: flex-end;
	}

	.mdl-dialog__actions {
		@include mq("tablet", "max") {
			//flex-flow: column-reverse;
			justify-content: center;
		}

		.mdl-cell {
			@include mq("tablet", "max") {
				display: flex;
				justify-content: center;
			}
		}
	}

	@include mq("phone-wide", "max") {
		width: calc(100% - 20px);

		.mdl-dialog__content {
			padding-left: 0;
			padding-right: 0;
		}

		.modal-title {
			padding-left: 10px;
			padding-right: 10px;
		}

		.modal-dialog__content {
			padding-left: 0;
			padding-right: 0;
		}

		.mdl-grid {
			padding-left: 0;
			padding-right: 0;
		}

		.mdl-dialog__actions {
			.mdl-cell {
				width: 100%;
				margin-left: 0;
				margin-bottom: 15px;
			}

			.mdl-button {
				width: 100%;
			}
		}
	}
}

.mdl-radio.is-checked {

	.mdl-radio__outer-circle {
		border: 2px solid $brand-color;
	}

	.mdl-radio__inner-circle {
		background: $brand-color;
	}
}



.cookie-policy-wrapper {
	width: 100% !important;
}

.cookie-policy-controls__bottom>button {
	width: auto !important;
}

.cookie-policy-container {
	width: 100% !important;
}

.forgot-password {
	main {
		background: #fff !important;
	}

	.page-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0px auto;
		padding: 12px 12px 50px 12px;

		form {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			label {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-top: -25px;

				i {
					margin-right: 10px;
				}
			}

		}
	}
}

main {
	flex: 1 0 auto;
}

.mdl-menu__item {
	&:hover {
		background-color: #f5f5f5;
	}

	a:hover {
		color: $brand-color;
	}
}


.eventDateLabel {
	font-size: 14px;
	color: $brand-color !important;
}

.eventDateInput {
	border-bottom: none !important;
}

.duet-date__input {
	border: 1px solid rgba(0, 0, 0, .12) !important;
}

.mdl-button--icon {
	color: $brand-color;

	&:hover {
		background-color: #f7f7f7;
	}
}

.mdl-checkbox.is-upgraded {
	max-width: 225px !important
}

.hideThis .desktop-nav {
	display: none !important;
}


.duetDateInput {
	border-bottom: 0px !important;
}

#shipping-modal .close-btn,
#shipping-modal .close-container {
	display: none;
}

#shipping-modal{
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);
    position: fixed;
    padding: 30px !important;
    max-width: 450px;
    @include mq('desktop-wide', 'min') {
        left: 25%;
    }
}

#shipping-modal .modal-title {
	min-height: initial;
	border-bottom: initial;
	padding: initial;
}

#shipping-modal .modal-title__major {
	font-size: 21px;
}

#shipping-modal .modal-title__major .material-icons {
	padding-right: 5px;
	color: #e60000;
}


#shipping-modal .mdl-dialog__content {
	color: initial;
	padding: 20px 0;
}

#shipping-modal .mdl-dialog__content p {
	color: #000;
}

dialog[open] {
	display: block !important;
}

.restricted-items-section {
	padding: 20px 20px 0;

	li {
		font-size: 13px;
		line-height: 1.75em;
	}
}

// Covid 19 popup
.swal2-container.covid-19 .swal2-confirm {
	background-color: $brand-color !important;
}


#uploadifive-file_upload{
    position: inherit;
    z-index: 0;
    &:hover{
        cursor: pointer;
    }
}