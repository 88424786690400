/*
 * Flex UX Framework
 *
 * Filename: category.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.category-list {
	main {
		background-color: #ebebeb;
		min-height: calc(100vh - 65px) !important;
		padding-top: 160px;

		@include mq('tablet', 'max') {
			padding-top: 56px;
		}
	}

	.page-header {
		background: #E0F4FD;
		margin-top: $header-height;
		height: 175px;
		//display: flex;
		//justify-content: center;
		//align-items: center; 
	}

	.page-body {
		font-family: $primary-font-face;
		font-size: 13px;
		display: flex;
		flex-wrap: wrap;
		max-width: 1180px;
		margin-top: 24px;

		@include mq("tablet", "min") {
			padding: 0px 20px;
		}

		.products-container {
			flex-grow: 1;
			flex-basis: calc(100% - 260px);
			order: 4;
			overflow-x: hidden;

			@include mq('tablet', 'max') {
				margin-right: 0;
			}

			@include mq("phone-wide", "max") {
				margin: 0;
				padding: 0 10px 10px;
			}
		}

		.grid-container {
			display: flex;
			flex-flow: row nowrap;
		}

		@include mq('tablet', 'max') {
			// flex-direction: row-reverse;
		}

		@include mq("phone-wide", "max") {
			padding: 15px 6px 6px 6px;
			margin-top: 0;
		}
	}

	.products-header {
		flex: 0 0 100%;
		width: 100%;
		order: 1;
		// height: 41px;
		padding-bottom: 5px;
		margin-bottom: 15px;

		@include mq('tablet', 'max') {
			align-self: center;
			order: inherit;
			// flex: 0 0 100%;
			display: flex;
			justify-content: flex-start;
			margin-bottom: 5px;
		}

		@include mq('tablet', 'min') {
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			flex: 4 4 100%;
		}

		@include mq('phone-small', 'max') {
			flex: 2 2 30%;
		}

		.title {
			display: flex;
			align-items: center;
			flex: 1 1 50%;
			font-size: 30px;
			height: 48px;
			font-weight: 700;
			font-family: $primary-font-face;
			color: $secondary-font-color;

			@include mq('tablet', 'min') {
				line-height: 62px;
			}
		}
	}
}
@include mq("tablet", "max") {
	.category-list {
		.page-body {
			flex-direction: row-reverse;
		}

		.products-header {
			order: -1;
			flex: 2 2 47%;
			display: flex;
			justify-content: flex-end;

			.title {
				display: none;
			}
		}
	}
}

@include mq("phone-wide", "max") {
	.category-list {
		.products-header {
			// justify-content: space-between;
			padding: 0px 10px;
			width: 100%;
		}

		.page-body {
			.products-container {
				flex-basis: 100%;
				width: 100%;
			}
		}
	}
}

@include mq("phone", "max") {
	.category-list {
		.products-header {
			flex-direction: column;
			
			.title {
				margin-bottom: 15px;
			}
		} 
	}
}