/*
 * Flex UX Framework
 *
 * Filename: item-shipping-calendar.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.product-description-text{
	@include mq("tablet", "max"){
		line-height: 2;
	}

	table{
		td.mdl-data-table__cell--non-numeric, th.mdl-data-table__cell--non-numeric{
			text-align: left !important;
		    font-family: $secondary-font-face;
		    text-transform: capitalize;
		    font-weight: 700;
		    font-size: 13px;
			@include mq("tablet", "max"){
				font-size: 13px;
			}
		}
	}
}

.product-shipping-info {

	&-container {
	    display: flex;
	    justify-content: flex-start;
	    padding: 30px 0 10px;
	}
    
    &-icon.material-icons {
	    position: relative;
	    left: -3px;
	    font-size: 36px;
	    margin-right: 14px;
	    color: $secondary-font-color;
	}

	&-text p {
	    font-size: 13px;
	    line-height: 1.25;
	    margin-bottom: 11px;
	}
}
.product-shipping-in-hands {

	&-container {
	    color: $brand-color;
	    display: flex;
	    align-items: center;
	    padding: 20px 16px 20px 0;

		@include mq("phone-wide", "max") {
			padding-right: 0;
		}
	}

	&-icon.material-icons {
	    font-size: 36px;
	    margin-right: 14px;
	}

	&-text {
		font-weight: 700;
	    font-size: 16px !important;
	    font-family: $secondary-font-face;
	    // line-height: 1.5em;
	    text-transform: uppercase;
	    .in-hands-text, 
		.in-hands-title {
    		float: left;
    		clear: both;
    		sup {
    			font-size: 10px;
			    position: relative;
			    top: 2px;
    		}
		}
	}
}
.mdl-data-table {
    border: none;
    width: 100%;

    tr, td, th {
    height: 15px !important;
    padding: 2px 5px;
	}

    th {
	    border-top: 1px solid rgba(0,0,0,0.12);
	    border-bottom: 1px solid rgba(0,0,0,0.12);
	    color: $secondary-font-color;
	}

	tr {
		height: 15px !important;
	    padding: 2px 5px;
	    &:hover{
	    	background: rgba(0, 0, 0, 0.0) !important;
	    }
	}

	&__cell--colored {
    color: $brand-color;
	}

	td:first-of-type,
	th:first-of-type {
    	padding-left: 24px;
	}

	th:first-of-type {
	    padding-left: 0;
	}

	td:last-of-type, 
	th:last-of-type {
    	padding: 0;
	}
}

