/*
 * Flex UX Framework
 *
 * Filename: sort-by.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


#sort-by.btn {
	align-self: flex-end;
	padding: 3px 7px;
	padding-right: 10px;
	letter-spacing: 0;
	font-size: 14px;
	line-height: normal;
	text-transform: uppercase;
	font-weight: bold;
	order: 2;
	align-self: center;
	display: flex;
	align-items: center;
	justify-content: space-around;
	background: #fff;
	font-family: $secondary-font-face;
	border-radius: 5px;
	margin: 0;
	color: $secondary-font-color;

	@include mq('tablet', 'max') {
		padding: 10px 15px;
		border-radius: 5px;
		order: 3;
	}

	@include mq('tablet', 'min') {
		padding: 11px 16px;
		min-width: 82px;
		// height: 41px;
	}

	@include mq("phone-wide", "max") {
		padding-right: 25px;
		color: $secondary-font-color;
		justify-content: flex-start;
	}

	@include mq("phone", "max") {
	}

	&::after {
		font-family: 'Material Icons';
		font-weight: 100;
		content: '\e314';
		display: inline-block;
		transform: rotate(-90deg);
		font-size: 24px;
		color: $brand-color;
		position: relative;
	}
}

.sort-by-container {
	font-family: $secondary-font-face;
	font-size: 14px;

	.mdl-menu__container {
		left: initial !important;
		top: initial !important;
		margin-left: -23px;

		.mdl-menu__item:hover {
			background-color: #F7F7F7;
		}

		a {
			text-decoration: none;
			color: $secondary-font-color;

			&:hover {
				color: $brand-color;
			}
		}

		@include mq('tablet', 'max') {
			margin-left: -40px;
		}
	}
}

@include mq("phone-wide", "max") {
	.sort-by-container .mdl-menu__container {
		margin-left: -50px;
	}
}

@include mq("phone-wide", "max")  {
	.sort-by-container .mdl-menu__container {
		margin-left: 0;
	}

	#sort-by.btn {
		position: relative;

		margin-bottom: 7px;

		&:after {
			content: '\e313';

			position: absolute;
			right: 6px;
			top: 50%;

			margin-bottom: 5px;

			transform: translateY(-50%);
		}
	}
}